<template>
  <div>
    <MoviesSingle/>
  </div>
</template>
<script>
import MoviesSingle from '@/components/MoviesSingle';
export default {
  name: 'MovieSIngle',
  components: {
    MoviesSingle
  },
}
</script>
